<template>
    <div class="rounded-2xl bg-white p-8">
        <div class="">
                <div class="flex flex-wrap items-center content-between justify-between">
                    <span class="font-semibold text-base">Riwayat Kontrak</span>
                    <div class="flex">
                        <div>
                            <button class=" text-white rounded text-center bg-blue-500 px-4 py-2" @click="openEdit('')">Tambah</button>
                        </div>
                    </div>
                </div>

                 <div class="mt-4">
                    <table class="w-full">
                        <thead>
                            <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                <td class="py-4">Tanggal Mulai</td>
                                <td>Tanggal Berakhir</td>
                                <td>Catatan</td>
                                <td >Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                <td class="py-4"> 
                                    <span>{{parseDate(item.start_date,'YYYY-MM-DD')}}</span>
                                </td>
                                <td>
                                    <span>{{parseDate(item.expired_date,'YYYY-MM-DD')}}</span>
                                </td>
                                <td>
                                    <span>{{item.note}}</span>
                                </td>
                                <td>
                                    <button class="text-white shadow rounded mr-1 h-8 w-8 text-xs hijau inline" @click="openEdit(item.id)">
                                        <i class="far fa-edit"></i>
                                    </button>
                                
                                    <button class="text-white shadow rounded h-8 w-8 text-xs merah" @click="openDelete(item.id)">
                                        <i class="far fa-times" ></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <t-modal
                :header="`Hapus Data`"
                class="flex flex-wrap content-center"
                style="z-index:212000 !important"
                v-model="modal_dialog"
                :classes="class_modal"
                >
                    <p class="text-lg">Yakin Hapus Data ?</p> 
                    <div class="grid grid-cols-2 gap-4 mt-4">
                        <button
                        @click="closeModal"
                        type="submit"
                        :class="`col-span-1 text-center py-2 rounded
                        
                        bg-gray-100
                        
                        disabled:opacity-50
                        focus:outline-none my-1`"


                    >Batal</button>
                    <button
                        @click="deleteData()"
                        type="submit"
                        :class="`col-span-1 text-center py-2 rounded
                        text-white
                            merah
                        disabled:opacity-50
                        focus:outline-none my-1`"


                    >Hapus</button>
                    </div>
                    
                </t-modal>

                 <t-modal
                    :header="`${addData.id ? 'Ubah Kontrak' : 'Tambah Kontrak'}`"
                    class="flex flex-wrap content-center"
                    v-model="modal_edit"
                style="z-index:212000 !important"

                    :classes="class_modal"
                    >
                        <ValidationObserver v-slot="{ invalid}" ref="form" >
                        <form @submit.prevent="addUser()" class="w-full" >
                        
                        <ValidationProvider rules="required" v-slot="{ errors }" name="Tanggal Kontrak" vid="start_date">
                        <label for="" class="block mb-2">Nama</label>  
                        <input
                        v-model="addData.start_date"
                        type="date"
                        :class="`block border border-gray-200 w-full p-2 px-4 rounded-full  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                        name="start_date"
                        ref="start_date"
                        id="start_date"
                        placeholder="Nama" />
                        <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider rules="required|numeric" v-slot="{ errors }" name="Tahun Kontrak" vid="contract" v-if="!addData.id">
                        <label for="" class="block mb-2 mt-4">Tahun Kontrak</label>  
                        <input
                        v-model="addData.contract"
                        type="text"
                        :class="`block border border-gray-200 w-full p-2 px-4 rounded-full  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                        name="contract"
                        ref="contract"
                        id="contract"
                        placeholder="contoh: 1,2,3, dst.." />
                        <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider rules="required" v-slot="{ errors }" name="Tanggal Berakhir" vid="expired_date" v-else>
                        <label for="" class="block mb-2 mt-4">Tanggal Berakhir</label>  
                        <input
                        v-model="addData.expired_date"
                        type="date"
                        :class="`block border border-gray-200 w-full p-2 px-4 rounded-full  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                        name="expired_date"
                        ref="expired_date"
                        id="expired_date"
                        placeholder="Nama" />
                        <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                        </ValidationProvider>

                         <ValidationProvider v-slot="{ errors }" name="Catatan" vid="note" >
                        <label for="" class="block mb-2 mt-4">Catatan</label>  
                        <textarea value="Hello world" v-model="addData.note"
                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                
                                name="my-textarea" />
                        <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>
                        </ValidationProvider>
                        
                        

                        <button
                            :disabled="invalid"
                            type="submit"
                            :class="`w-full text-center py-2 rounded
                            text-white
                            hover:bg-blue-900 bg-blue-500
                            disabled:opacity-50
                            focus:outline-none my-1`"


                        >Simpan</button>
                    </form>
                    </ValidationObserver>
                        
                    </t-modal>
        </div>
    </div>
</template>

<script>
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import moment from "moment"
import globalMixin from '../mixin/global'
export default {
    props:['data','type','detail'],
    data:()=>({
        delete_id:'',
        modal_edit:false,
        disabled:false,
        modal_dialog:false,
        addData:{
           
            start_date:'',
            expired_date:'',
            contract:'',
            note:'',
          
            id:''
        },
    }),
    mixins:[globalMixin],
    components:{
         ValidationProvider,ValidationObserver
    },
   
    methods:{
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },
        async openEdit(id = null) {
            let user = ''
            if (id) {
                user = await this.data.find(x => x.id == id)
                this.addData.start_date = moment(user.start_date).format('YYYY-MM-DD').toString()
                this.addData.expired_date = moment(user.expired_date).format('YYYY-MM-DD').toString()
                this.addData.note = user.note

                this.addData.id = user.id
            } else {
                this.addData.start_date = moment(this.detail.expired_date).format('YYYY-MM-DD').toString()
            }
            this.modal_edit = !this.modal_edit
        },
        closeModal() {
            
            this.modal_dialog = !this.modal_dialog
        },
        addUser() {
            if (this.type == 'customer') {
                this.addData.customer_id = this.$route.params.id
                
            } else {
                this.addData.reseller_id = this.$route.params.id

            }
            let url = 'v1/contract'
            if (this.addData.id) {
                url = url + '/' + this.addData.id
                this.axios.put(url,this.addData,this.config)
                .then((ress) => {
                    this.refres()
                    this.$snack.success({
                        text: ress.data.message,

                    })
                    this.closeEdit()
                })
            } else {
                this.axios.post(url,this.addData,this.config)
                .then((ress) => {
                    this.refres()
                    this.$snack.success({
                        text: ress.data.message,

                    })
                    this.closeEdit()
                })
            }
            
        },
        refres(){
            this.$emit('getData')
        },
        closeEdit(){
            this.modal_edit = !this.modal_edit
             this.addData={
                   
                start_date:'',
                expired_date:'',
                contract:'',
                note:'',
            
                id:''
            }
        },
    
      
        deleteData(){
            this.axios.delete('v1/contract/' + this.delete_id,this.config)
            .then((ress) => {
                 this.closeModal()
                 this.refres()
                  this.$snack.success({
                    text: ress.data.message,

                })
            })
        },
    }
}
</script>